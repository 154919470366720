export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'regionCode',
    label: 'field.region',
    hideToggle: true,
  },
  {
    key: 'agentCode',
    label: 'field.agent',
    hideToggle: true,
  },
  {
    key: 'outletName',
    label: 'field.outlet',
    localization: true,
    subKey: 'outletCode',
    hideToggle: true,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    subKey: 'salespersonIdCard',
    hideToggle: true,
  },
  {
    key: 'visitedDate',
    label: 'field.visitedDate',
    type: 'date',
    hideToggle: true,
  },
  {
    key: 'checkinDate',
    label: 'field.checkinTime',
    type: 'time',
    tdClass: 'text-success',
    hideToggle: true,
  },
  {
    key: 'checkoutDate',
    label: 'field.checkoutTime',
    type: 'time',
    tdClass: 'text-danger',
    hideToggle: true,
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'isOnRoute',
    label: 'field.type',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'noOrderReason',
    label: 'field.noOrderReason',
    localization: true,
  },
  {
    key: 'displayProductRemark',
    label: 'field.displayProductRemark',
  },
  {
    key: 'brandingRemark',
    label: 'field.brandingRemark',
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
