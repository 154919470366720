export default [
  {
    key: 'regionCode',
    label: 'field.region',
    width: 2500,
  },
  {
    key: 'agentCode',
    label: 'field.agent',
    width: 2500,
  },
  {
    key: 'outletCode',
    label: 'field.outletCode',
    width: 3500,
  },
  {
    key: 'outletName',
    label: 'field.outlet',
    localization: true,
    width: 6000,
  },
  {
    key: 'salespersonIdCard',
    label: 'field.idCard',
    width: 3000,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
  },
  {
    key: 'visitedDate',
    label: 'field.visitedDate',
    type: 'date',
    width: 4000,
  },
  {
    key: 'checkinDate',
    label: 'field.checkinTime',
    type: 'time',
    width: 4000,
  },
  {
    key: 'checkoutDate',
    label: 'field.checkoutTime',
    type: 'time',
    width: 4000,
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 3000,
    options: ["field.notVisit", "field.visiting", "field.visited"]
  },
  {
    key: 'isOnRoute',
    label: 'field.type',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 2500,
    options: ["field.offRoute", "field.onRoute"]
  },
  {
    key: 'noOrderReason',
    label: 'field.noOrderReason',
    localization: true,
    width: 6000,
  },
  {
    key: 'displayProductRemark',
    label: 'field.displayProductRemark',
    width: 6000,
  },
  {
    key: 'brandingRemark',
    label: 'field.brandingRemark',
    width: 6000,
  },
];
